<template>
  <div class="not-found">
    <div class="four-o-four-image"></div>
    <div class="text"><b>404:</b> {{ $t('page_not_found_text') }}</div>
    <router-link
      v-if="isAuthenticated"
      class="text-link"
      :to="$t('path_my_propositions')"
      >{{ $t('page_not_found_action') }}</router-link
    >
    <a v-else class="text-link" href="/">{{
      $t('page_not_found_action_not_verified')
    }}</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PageNotFound',
  head: {
    title() {
      return {
        inner: this.$t('meta_title_404')
      };
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },
  mounted() {
    window.pageNotFound = true;
    window.prerenderPaused = false;
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.four-o-four-image {
  width: 600px;
  height: 300px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/img/404-transparent.png');

  @media ($mobile) {
    width: 300px;
    background-size: contain;
    background-image: url('../../assets/svg/placeholderImage/placeholder-image-medium.svg');
  }
}

.text {
  font-size: 1.4rem;
}
.text-link {
  margin-top: 20px;
  font-size: 1.1rem;
}
</style>
